@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";
// Vex Core
@import "./@vex/styles/core";

/*
  You can override any CSS Variable or style here
  Example:

  :root {
   --vex-sidenav-background: #333333;
  }

  All possible variables can be found in @vex/styles/core or by simply inspecting the element you want to change
  in Chrome/Firefox DevTools
*/

/**
  Uncomment the below code and adjust the values to fit your colors, the application will automatically adjust
 */
html {
  // --vex-color-primary: blue;
  // --vex-color-primary-contrast: white;

  // --vex-color-accent: yellow;
  // --vex-color-accent-contrast: black;

  // --vex-color-warn: yellow;
  // --vex-color-warn-contrast: black;
}
.mat-mdc-snack-bar-container {
  &.success-bg {
    --mdc-snackbar-container-color: #36B37E;
  }
  &.failure-bg {
    --mdc-snackbar-container-color: #FF5630;
  }
  &.default-bg {
    --mdc-snackbar-container-color: #2196f3;
  }
  &.warning-bg {
    --mdc-snackbar-container-color: #FFAB00;
  }
  --mat-mdc-snack-bar-button-color: #fff;
  --mdc-snackbar-supporting-text-color: #fff;
}