@font-face {
  font-family: "Inter var";
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url("/assets/fonts/Inter-roman-var.woff2?v=3.19") format("woff2");
  font-named-instance: "Regular";
}

@font-face {
  font-family: "Inter var";
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url("/assets/fonts/Inter-italic-var.woff2?v=3.19") format("woff2");
  font-named-instance: "Italic";
}
